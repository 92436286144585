import { FC } from 'react';

interface ComponentProp {
  icon: any,
  title: string,
  caption: string,
}

const BenefitsCard: FC<ComponentProp> = ({ icon, title, caption }) => {
  return (
    <div className="group">
      <div className="shadow-md grid justify-center py-5 px-5 bg-[#EEEAF2] group-hover:bg-gray-50 transition-all pointer-events-none">
        <div className="grid justify-center">
          <div className="w-28 h-28 rounded-full bg-[#D6E9E7] grid justify-center items-center">
            {icon}
          </div>
        </div>
        <h2 className="mt-3 text-gray-900 font-semibold text-2xl text-center group-hover:text-[#319385]">
          {title}
        </h2>
        <p className="mt-5 text-base text-[#1A1A1A] text-center mb-3">
          {caption}
        </p>
      </div>
    </div>
  );
};

export default BenefitsCard;
