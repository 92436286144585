import { CheckIcon } from "@heroicons/react/outline";

const pricing = {
  tiers: [
    {
      title: "Intro",
      price: 200,
      frequency: "/month",
      description: "For most businesses that want to otpimize web queries",
      features: [
        "All limited links",
        "Own analytics platform",
        "Chat support",
        "Optimize hashtags",
        "Unlimited users",
      ],
      cta: "Choose plan",
      mostPopular: false,
    },
    {
      title: "Base",
      price: 500,
      frequency: "/month",
      description: "For most businesses that want to otpimize web queries",
      features: [
        "All limited links",
        "Own analytics platform",
        "Chat support",
        "Optimize hashtags",
        "Unlimited users",
      ],
      cta: "Choose plan",
      mostPopular: false,
    },
    {
      title: "Pro",
      price: 1000,
      frequency: "/month",
      description: "For most businesses that want to otpimize web queries",
      features: [
        "25 products",
        "Up to 10,000 subscribers",
        "Advanced analytics",
        "24-hour support response time",
        "Marketing automations",
      ],
      cta: "Choose plan",
      mostPopular: true,
    },
    {
      title: "Enterprise",
      price: 2000,
      frequency: "/month",
      description: "For most businesses that want to otpimize web queries",
      features: [
        "Unlimited products",
        "Unlimited subscribers",
        "Advanced analytics",
        "1-hour, dedicated support response time",
        "Marketing automations",
        "Custom integrations",
      ],
      cta: "Choose plan",
      mostPopular: false,
    },
  ],
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PlanCards() {
  return (
    <div className="max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl mx-auto py-5 lg:py-10 bg-white font-mulish">
      {/* Tiers */}
      <div className="mt-10 space-y-12 lg:space-y-0 grid md:grid-cols-2 xl:grid-cols-4 md:gap-4">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className={classNames(
              tier.mostPopular ? "bg-[#319385]" : "bg-white",
              "relative p-8 border border-gray-200 rounded-2xl shadow-sm flex flex-col"
            )}
          >
            <div className="flex-1">
              {tier.mostPopular ? (
                <p className="absolute top-0 py-1.5 px-4 bg-[#53237C] rounded-full text-xs font-semibold uppercase tracking-wide text-white transform right-4 translate-y-1/2">
                  Most popular
                </p>
              ) : null}
              <p
                className={classNames(
                  tier.mostPopular ? " text-white" : "text-[#53237C]",
                  "mt-4 flex items-baseline"
                )}
              >
                <span className="text-2xl font-semibold mr-1">KES</span>
                <span className="text-4xl font-extrabold tracking-tight">
                  {tier.price}
                </span>
                <span className="ml-1 text-xl font-semibold">
                  {tier.frequency}
                </span>
              </p>
              <h3
                className={classNames(
                  tier.mostPopular ? "text-white" : "text-[#53237C]",
                  "text-2xl font-semibold mt-7"
                )}
              >
                {tier.title}
              </h3>
              <p
                className={classNames(
                  tier.mostPopular ? " text-white" : "text-gray-500",
                  "mt-6"
                )}
              >
                {tier.description}
              </p>

              {/* Feature list */}
              <ul className="mt-6 space-y-2">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon
                      className={classNames(
                        tier.mostPopular ? "text-white" : "text-[#319385]",
                        "flex-shrink-0 w-6 h-6"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        tier.mostPopular ? " text-white" : "text-gray-500",
                        "ml-3"
                      )}
                    >
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <a
              href="#hsd"
              className={classNames(
                tier.mostPopular
                  ? "bg-[#EBF5F3] text-[#319385] hover:bg-indigo-100"
                  : "bg-[#EBF5F3] text-[#319385] hover:bg-indigo-100",
                "mt-8 block w-full py-3 px-6 border border-transparent rounded-full text-center font-medium"
              )}
            >
              {tier.cta}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
