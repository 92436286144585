import { FC } from "react";
import Button from "./partials/Button";
import Vector from "../../src/images/vector1.svg";
import HeroImage from "../images/calendar.png";

const Hero: FC = () => {
  return (
    <section className="max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl mx-auto font-mulish">
      {/* container mx-auto */}
      <div className="pl-4 lg:pl-6 py-10 lg:py-0  mb-20" id="home">
        <div className="items-center grid md:grid-cols-2 lg:grid-cols-2">
          <div className="w-full">
            <div className="max-w-md md:max-w-xl lg:max-w-4xl">
              <div className="flex items-center space-x-2 mb-5 text-[#53237C]">
                <img
                  className="object-center object-cover"
                  src={Vector}
                  alt="vector-line"
                />
                <span className="text-base font-medium">
                  Health not Wealth.
                </span>
              </div>

              <h1 className="text-3xl font-extrabold text-black capitalize lg:text-4xl xl:text-6xl leading-snug mr-20 hidden xl:block">
                A Commitment to
              </h1>
              <h1 className="text-3xl font-extrabold text-black capitalize lg:text-4xl xl:text-6xl leading-snug mr-20 mt-4 hidden xl:block">
                Community.
              </h1>
              {/* Mobile */}
              <h1 className="text-4xl md:text-4xl font-extrabold text-black capitalize leading-snug md:leading-normal xl:hidden">
                A Commitment to Community.
              </h1>
              <p className="mt-10 text-[#333333] text-2xl font-medium hidden xl:block leading-snug">
                Manage all your patients, appointments and clinic <br />{" "}
                operations with no time wasted.
              </p>
              <p className="mt-5 text-[#333333] text-xl md:text-lg font-normal pr-1 xl:hidden leading-snug">
                Manage all your patients, appointments and clinic operations
                with no time wasted.
              </p>

              <div className="lg:flex lg:space-x-3 pr-10 md:px-0">
                <div className="w-48 lg:w-full">
                  <Button />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-12 lg:mt-0 pr-4">
            <img
              className="w-full h-full object-center object-cover optimized"
              src={HeroImage}
              alt="Calendar_image"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero