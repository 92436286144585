import { FC } from "react";
import PlanCards from "./partials/PlanCards";

const Plan: FC = () => {
  return (
    <section className="mb-10 mt-12 px-4 xl:px-0 font-mulish" id="pricing">
      <div className="grid justify-center">
        <div className="grid">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mt-4 text-[#194A43]">
            Our Best Plans For You.
          </h2>
        </div>
      </div>

      <div>
        <PlanCards />
      </div>
    </section>
  );
}

export default Plan