import React from "react";

const Button = () => {
  return (
    <a href="#signup">
      <button className="w-full px-6 py-2 mt-8 text-sm font-medium text-white capitalize transition-colors duration-200 transform bg-[#319385] rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500 flex items-center">
        Sign Up for Free
        <svg
          className="w-6 h-6 text-white ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </button>
    </a>
  );
};
export default Button;
