import BenefitsCard from "./partials/BenefitsCard";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsShieldCheck, BsClock, BsHouseDoor } from "react-icons/bs";

const Benefits = () => {
  return (
    <section
      className="mx-auto max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl px-4 lg:px-2 font-mulish"
      id="features"
    >
      <div className="grid justify-center">
        <div className="grid">
          <button className="bg-[#EEEAF2] py-2 px-4 rounded-md justify-self-center pointer-events-none">
            <span className="text-sm text-[#53237C] font-medium pointer-events-none">
              Why You Should Trust Us
            </span>
          </button>
          <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold mt-4 text-[#194A43]">
            Get Benefits & Advantages.
          </h2>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-12">
        <BenefitsCard
          icon={
            <HiOutlineUserGroup className="w-10 h-10 text-black group-hover:text-[#319385]" />
          }
          title="Maximum Exposure"
          caption="Patients from all over the country can view your profile and book
            appointments."
        />
        <BenefitsCard
          icon={
            <BsShieldCheck className="w-10 h-10 text-black group-hover:text-[#319385]" />
          }
          title="Private & Secure"
          caption="All your data and patient’s data are well protected and safeguarded by encription."
        />
        <BenefitsCard
          icon={
            <BsClock className="w-10 h-10 text-black group-hover:text-[#319385]" />
          }
          title="100% Flexibility"
          caption="Your time your rules.You can let patients know when you are available for consultations."
        />
        <BenefitsCard
          icon={
            <BsHouseDoor className="w-10 h-10 text-black group-hover:text-[#319385]" />
          }
          title="Have a Clinic?"
          caption="Managing your clinic has never been easier. Say goodbye to all the hustle and struggle."
        />
      </div>
    </section>
  );
}

export default Benefits