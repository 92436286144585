import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Benefits from "./components/Benefits";
import Records from "./components/Records";
import Plan from "./components/Plan";
import Testimonial from "./components/Testimonial";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Benefits />
      <Records />
      <Plan />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default App;
