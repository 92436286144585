import { useScrollPosition } from "./hooks/useScrollPosition";
import { useState, FC } from "react";
import Logo from "../images/logo.png";

const Navbar: FC = () => {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const scrollPosition = useScrollPosition();

  const [openNav, setOpenNav] = useState(false);

  const [activeHome, setActiveHome] = useState(true);
  const [activeFeature, setActiveFeature] = useState(false);
  const [activePricing, setActivePricing] = useState(false);

  const resetActive = () => {
    setActiveHome(false);
    setActiveFeature(false);
    setActivePricing(false);
  };
  const handleActive = (arg: string) => {
    setOpenNav(false);
    resetActive();
    if (arg === "home") {
      setActiveHome(true);
    } else if (arg === "features") {
      setActiveFeature(true);
    } else if (arg === "pricing") {
      setActivePricing(true);
    }
  };

  return (
    <>
      <nav
        className={classNames(
          scrollPosition > 0 ? "shadow-lg shadow-[#319385]/20" : "shadow-none",
          "bg-white pt-1 pb-1 pl-4 md:pl-8 transition-shadow sticky z-40 top-0"
        )}
      >
        <div
          className={`bg-white px-4 md:px-0 sm:px-4 ${
            scrollPosition > 0
              ? "py-1 md:py-3 transition-all delay-100 duration-300"
              : "py-5 md:py-10 transition-all delay-100 duration-300"
          } font-mulish`}
        >
          <div className="max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl flex flex-wrap justify-between items-center mx-auto">
            <a href="#home" className="flex items-center">
              <img
                className="object-cover object-center w-auto transition-all duration-300 h-10 hidden lg:block optimized"
                src={Logo}
                alt="MyDokta Logo"
              />
              <img
                className="object-cover object-center w-auto transition-all duration-300 lg:hidden"
                src={Logo}
                alt="MyDokta Logo"
              />
            </a>
            <div className="flex space-x-5 lg:order-2">
              <a href="#login">
                <button
                  type="button"
                  className="text-[#333333] focus:ring-1 focus:outline-none focus:ring-[#319385] font-normal rounded-lg text-base px-5 py-2 text-center mr-3 md:mr-0 hover:bg-[#319385] hover:text-white transition-all delay-200 hidden md:block"
                >
                  Log In
                </button>
              </a>
              <a href="#signup">
                <button
                  type="button"
                  className="text-white bg-[#319385] hover:bg-white hover:text-black hover:border hover:border-[#319385] border border-transparent transition-all delay-200 focus:ring-1 focus:outline-none focus:ring-[#319385] font-normal rounded-lg text-base px-5 py-2 text-center mr-3 md:mr-0 hidden md:block"
                >
                  Sign Up
                </button>
              </a>

              {openNav ? (
                <svg
                  className="p-2 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-100 transition-all w-11 h-11"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setOpenNav(false)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              ) : (
                <button
                  data-collapse-toggle="navbar-cta"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-100 transition-all"
                  aria-controls="navbar-cta"
                  aria-expanded="false"
                  onClick={() => setOpenNav(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-7 h-7 transition-all"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              )}
            </div>
            <div
              className="hidden justify-between items-center w-full lg:flex md:w-auto lg:order-1"
              id="navbar-cta"
            >
              <ul className="flex flex-col mt-4 md:flex-row md:space-x-10 md:mt-0 md:text-base md:font-normal">
                <li onClick={() => handleActive("home")}>
                  <a
                    href="#home"
                    className={`block py-2 pr-4 pl-3 hover:text-[#319385] md:p-0 ${
                      activeHome
                        ? "text-[#319385] border-b-4 border-[#319385]"
                        : "text-[#333333]"
                    }`}
                    aria-current="page"
                  >
                    Home
                  </a>
                </li>
                <li onClick={() => handleActive("features")}>
                  <a
                    href="#features"
                    className={`block py-2 pr-4 pl-3 hover:text-[#319385] md:p-0 ${
                      activeFeature
                        ? "text-[#319385] border-b-4 border-[#319385]"
                        : "text-[#333333]"
                    }`}
                  >
                    Features
                  </a>
                </li>
                <li onClick={() => handleActive("pricing")}>
                  <a
                    href="#pricing"
                    className={`block py-2 pr-4 pl-3 hover:text-[#319385] md:p-0 ${
                      activePricing
                        ? "text-[#319385] border-b-4 border-[#319385]"
                        : "text-[#333333]"
                    }`}
                  >
                    Pricing
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Sidebar */}
          <div
            className={`flex flex-col absolute z-40 left-0 top-0 lg:hidden transform h-screen overflow-y-scroll no-scrollbar w-64 bg-white shadow ease-in-out ${
              openNav
                ? "translate-x-0 transition-all delay-100 duration-300"
                : "-translate-x-64 transition-all delay-100 duration-300"
            }`}
          >
            <div className="flex justify-between items-center pl-5 pt-3">
              <a href="#home">
                <img
                  className="w-auto h-9 object-cover object-center mt-4 optimized"
                  src={Logo}
                  alt="MyDokta Logo"
                />
              </a>
            </div>
            <div className="mt-10 px-3">
              <ul className="flex flex-col mt-4 md:text-base md:font-normal">
                <li onClick={() => handleActive("home")}>
                  <a
                    href="#home"
                    className={`block py-3 pr-4 pl-3 hover:text-[#319385] lg:p-0 ${
                      activeHome
                        ? "text-[#319385] border-b-4 border-[#319385]"
                        : "text-[#333333]"
                    }`}
                    aria-current="page"
                  >
                    Home
                  </a>
                </li>
                {/* features */}
                <li onClick={() => handleActive("features")}>
                  <a
                    href="#features"
                    className={`block py-3 pr-4 pl-3 hover:text-[#319385] lg:p-0 ${
                      activeFeature
                        ? "text-[#319385] border-b-4 border-[#319385]"
                        : "text-[#333333]"
                    }`}
                  >
                    Features
                  </a>
                </li>
                <li onClick={() => handleActive("pricing")}>
                  <a
                    href="#pricing"
                    className={`block py-3 pr-4 pl-3 hover:text-[#319385] lg:p-0 ${
                      activePricing
                        ? "text-[#319385] border-b-4 border-[#319385]"
                        : "text-[#333333]"
                    }`}
                  >
                    Pricing
                  </a>
                </li>
              </ul>
              <div className="mt-10 grid gap-4 px-3 md:hidden">
                <a href="#login">
                  <button
                    type="button"
                    className="text-[#333333] focus:ring-0 focus:outline-none font-normal rounded-xl text-base px-5 py-1 text-center mr-3 md:mr-0 hover:bg-[#319385] hover:text-white transition-all delay-200 border border-[#319385]"
                  >
                    Log In
                  </button>
                </a>
                <a href="#signup">
                  <button
                    type="button"
                    className="text-white bg-[#319385] hover:bg-white hover:text-black hover:border hover:border-[#319385] border border-transparent transition-all delay-200 focus:ring-0 focus:outline-none font-normal rounded-xl text-base px-5 py-1 text-center mr-3 md:mr-0"
                  >
                    Sign Up
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
