import { FC } from 'react';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const Testimonial: FC = () => {
  return (
    <section className="max-w-md sm:max-w-md md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto px-4 lg:px-2 font-mulish mb-20">
      <div className="grid justify-center">
        <div className="grid">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mt-4 text-[#194A43]">
            What Our Doctors Are Saying About Us
          </h2>
        </div>
      </div>

      <section className="mt-5 lg:mt-10">
        <Splide aria-label="My Favorite Images">
          <SplideSlide className="md:px-12 lg:px-20">
            <div className="px-5 lg:px-12 py-5 lg:py-10 mx-auto mt-16 bg-[#EBF5F3] rounded-lg shadow-lg">
              <div className="flex justify-center -mt-16">
                <img
                  className="object-cover object-center w-24 h-24 rounded-full lg:-mt-5"
                  alt="Testimonial avatar"
                  src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlb3BsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                />
              </div>

              <p className="mt-7 text-gray-600 text-center">
                Tanahair is the friendliest and most efficient company I have
                ever used. The whole thing takes time to introduce the product
                and as a result puts forward only the best opportunities that
                really suit you. they help from start to finish to create a
                great product identity for your company
              </p>

              <div className="grid justify-center mt-6">
                <p className="text-lg font-bold text-center">Shalima Hayden</p>
                <p className="text-sm font-light text-center mt-1">Doctor</p>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide className="md:px-12 lg:px-20">
            <div className="px-5 lg:px-12 py-5 lg:py-10 mx-auto mt-16 bg-[#EBF5F3] rounded-lg shadow-lg">
              <div className="flex justify-center -mt-16">
                <img
                  className="object-cover object-center w-24 h-24 rounded-full lg:-mt-5"
                  alt="Testimonial avatar"
                  src="https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                />
              </div>

              <p className="mt-7 text-gray-600 text-center">
                Tanahair is the friendliest and most efficient company I have
                ever used. The whole thing takes time to introduce the product
                and as a result puts forward only the best opportunities that
                really suit you. they help from start to finish to create a
                great product identity for your company
              </p>

              <div className="grid justify-center mt-6">
                <p className="text-lg font-bold text-center">Shalima Hayden</p>
                <p className="text-sm font-light text-center mt-1">Doctor</p>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide className="md:px-12 lg:px-20">
            <div className="px-5 lg:px-12 py-5 lg:py-10 mx-auto mt-16 bg-[#EBF5F3] rounded-lg shadow-lg">
              <div className="flex justify-center -mt-16">
                <img
                  className="object-cover object-center w-24 h-24 rounded-full lg:-mt-5"
                  alt="Testimonial avatar"
                  src=" https://images.unsplash.com/photo-1536416992256-1c91ce9ccdfd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fHBlb3BsZSUyMGJsYWNrfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                />
              </div>

              <p className="mt-7 text-gray-600 text-center">
                Tanahair is the friendliest and most efficient company I have
                ever used. The whole thing takes time to introduce the product
                and as a result puts forward only the best opportunities that
                really suit you. they help from start to finish to create a
                great product identity for your company
              </p>

              <div className="grid justify-center mt-6">
                <p className="text-lg font-bold text-center">Shalima Hayden</p>
                <p className="text-sm font-light text-center mt-1">Doctor</p>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </section>
    </section>
  );
}

export default Testimonial