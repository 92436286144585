import { FC } from "react";
import Button from "./partials/Button";
import SmartPhones from '../images/smartphones.png';

const Records: FC = () => {
  return (
    <section className="grid md:grid-cols-2 max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl mx-auto gap-6 mt-10 lg:mt-20 h-5/6 px-4 lg:px-0 font-mulish">
      <div className="bg-[#EBF5F3] px-12 py-16 rounded-t-3xl order-2 lg:order-1 mt-10 lg:mt-0">
        <img
          className="object-cover object-center w-full h-full rounded-2xl testImage"
          src={SmartPhones}
          alt="mobile"
        />
      </div>
      <div className="px-8 mt-28 h-96 order-1 lg:order-2">
        <div className="grid justify-center">
          <div className="grid">
            <button className="bg-[#EEEAF2] py-2 px-4 rounded-md pointer-events-none w-36">
              <span className="text-sm text-[#53237C] font-medium pointer-events-none">
                Patient Records
              </span>
            </button>
            <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mt-4 text-[#194A43]">
              Medical Record Keeping.
            </h2>
            <p className="mt-10 text-xl text-[#333333]">
              With the Digital Health Book, your patients can now share it to
              you and give you access to update their medical records
              seemlessly.
            </p>

            <div className="mt-10 w-48 lg:w-full">
              <Button />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Records